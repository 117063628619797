// import SlsWebLogger from 'js-sls-logger'
import 'isomorphic-fetch'


// const optsLog = {
//   host: 'cn-shenzhen.log.aliyuncs.com',
//   project: 'wechat-ai',
//   logstore: 'warn-store',
//   time: 10,
//   count: 10,
// };

const optsLog = {
  host: 'cn-shenzhen.log.aliyuncs.com',
  project: 'bixuange-email',
  logstore: 'store',
  time: 10,
  count: 10,
};


export default async function (data) {
  try {
    let keyStr = '';
    Object.keys(data).map((item, index) => {
      if(data[item]) {
        keyStr = `${keyStr}&${item}=${data[item]}`
      }
    });

    const img = new Image();
    img.src = `http://${optsLog.project}.${optsLog.host}/logstores/${optsLog.logstore}/track.gif?APIVersion=0.6.0${encodeURI(keyStr)}`;

    return {
      ok: true,
    };
  } catch (e) {
  }
}
