import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../components/Layout'

import { makeStyles } from "@material-ui/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {browserTitle, seoDescription, seoTitle, keywords} from "../data/contact";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";
import {BlackBack, Box4Title} from "../components/Common/Base";
import Stack from "@mui/material/Stack/Stack";
import Divider from "@mui/material/Divider/Divider";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {useState} from "react";

import logger from '../libs/slsWebLogger'
import ProjectHeader from "../components/Common/ProjectHeader";

const useStyles = makeStyles(() => {
  return {
    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mb25: {
      marginBottom: 25,
    },
    box5Content: {
      backgroundSize: 'contain',
      backgroundPosition: 'right center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${require('../images/about/07.png').default})`,
      height: '40vw',
      maxHeight: 550,
    },
    honor1: {
      color: 'rgba(255,255,255,0.6)',
    }
  }
});

const MainHeadBox = styled(Box)(({theme}) => {
  return {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${require('../images/contact/01.jpg').default})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '52vw',
  }
});

const MyInput = styled(TextField)(({theme}) => {
  return {
    background: '#071D26',
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = (props) => {
  const { location } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState({});

  const [openData, setOpen] = React.useState(false);

  const handleInputChange = (key) => (event) => {
    setInputValue({
      ...inputValue,
      [key]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    if(!inputValue.address) {
      setOpen({
        type: 'error',
        message: '请输入联系方式！'
      });
      return;
    }
    if(!inputValue.address) {
      setOpen({
        type: 'error',
        message: '请输入联系方式！'
      });
      return;
    }
    if(!inputValue.content) {
      setOpen({
        type: 'error',
        message: '请输入需求内容！'
      });
      return;
    }
    try{
      const loggerStatus = await logger(inputValue);
      if(loggerStatus.ok) {
        setInputValue({});
        setOpen({
          type: 'success',
          message: '信息已经提交成功，我们会尽快联系您，谢谢！'
        });
      } else {
        setOpen({
          type: 'error',
          message: '信息提交失败，请稍后重试！'
        });
      }
    } catch (e) {
      setOpen({
        type: 'error',
        message: '信息提交失败，请稍后重试！'
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <Snackbar
        open={!!openData}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={openData.type || 'info'} sx={{ width: '100%' }}>
          {openData.message}
        </Alert>
      </Snackbar>
      <ProjectHeader
        // backVideo={require('../../images/products/city3d/01.mp4').default}
        backImg={require('../images/contact/01.jpg').default}
        backVideo={'contact/01.m3u8'}
        title={'联系我们'}
        titleEn={'Get In Touch With Our Team'}
        // titleEn2={'Urban Weather Digital Twin System'}
        // subTitle={'三维城市气象仿真系统'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/*<MainHeadBox*/}
        {/*sx={{*/}
          {/*mb: 4,*/}
        {/*}}*/}
      {/*>*/}
        {/*<Container*/}
          {/*maxWidth="xl"*/}
          {/*sx={{*/}
            {/*p: 2,*/}
            {/*position: 'relative',*/}
            {/*zIndex: 5*/}
          {/*}}*/}
        {/*>*/}
          {/*<Typography*/}
            {/*variant="subtitle1"*/}
            {/*align="left"*/}
            {/*sx={{*/}
              {/*fontWeight: 'bold',*/}
              {/*fontSize: '3.5vw',*/}
            {/*}}*/}
          {/*>*/}
            {/*联系我们*/}
          {/*</Typography>*/}
          {/*<Typography*/}
            {/*variant="subtitle1"*/}
            {/*align="left"*/}
            {/*sx={{*/}
              {/*fontSize: '2.4vw',*/}
            {/*}}*/}
          {/*>*/}
            {/*Get In Touch With Our Team*/}
          {/*</Typography>*/}
        {/*</Container>*/}
        {/*<BlackBack />*/}
      {/*</MainHeadBox>*/}
      <Container
        ref={eleShow}
        maxWidth="xl"
        sx={{
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
        }}
      >
        <Grid
          className={classes.mb25}
          container
          spacing={{
            xs: 2,
            md: 4,
            lg: 8
          }}
          alignItems="stretch"
          columns={{ xs: 6, md: 12 }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mb: 2
            }}
          >
            <div>
              <Box4Title
                variant="h4"
              >
                给我们留言
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
              >
                Message us
              </Box4Title>
              <Divider sx={{mb: 2}} />
              <MyInput
                label="姓名 Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{mb: 2}}
                value={inputValue['name'] || ''}
                onChange={handleInputChange('name')}
              />
              <MyInput
                label="邮件 Email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{mb: 2}}
                value={inputValue['address'] || ''}
                onChange={handleInputChange('address')}
              />
              <MyInput
                placeholder="输入信息 Enter your message"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={4}
                fullWidth
                sx={{mb: 2}}
                value={inputValue['content'] || ''}
                onChange={handleInputChange('content')}
              />
              <Button onClick={handleSubmit} variant="contained">发送 send</Button>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mb: 2
            }}
          >
            <div>
              <Box4Title
                variant="h4"
              >
                我们的联系方式
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
              >
                Our contact
              </Box4Title>
              <Divider sx={{mb: 2}} />
              <Typography
                variant="subtitle1"
              >
                邮件 Email：
                <br />
                hello@bixuange.com
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
          pb: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
        }}
      >
        <Box style={{position: 'relative'}}>
          <img alt="" width="100%" src={require('../images/contact/02.jpg').default} />
          {/*<BlackBack />*/}
        </Box>

      </Container>
    </Layout>
  )
}


export default Contact;
